<template>
<!--转发分享，视频推荐-->
  <div>
  <div v-for="list in videoList" :key="list.id">
    <div class="recommend_video" @click="goVideo(list.vodId,list.fileId)">
<!--      <img class="recommend_pic" :src="list.cover_pics[0]" alt="">-->
      <van-image
          class="recommend_pic" :src="list.coverPics[0]"
      />
      <div class="recommend_title">
        <h3 :title="list.title">{{ list.title }}</h3>
        <div style="color: #BBBBBB;bottom:0;margin:0;padding: 0; position: absolute;display: grid;width: 100%;font-size:small">
          <span>{{list.name +' ' + list.jobTitle}}</span>
          <div style="display: flex;align-items: center;justify-content: space-between">
            <span style="overflow: hidden;text-overflow:ellipsis; white-space: nowrap">{{list.hospital}}</span>
            <span style="text-align: end"><van-icon name="eye-o" /> {{ list.browseCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <van-divider />
  </div>
  </div>
</template>

<script>
export default {
  name: "videoRecommended",
  props:['videoList'],
  methods:{
    goVideo(id,fileId){
      this.$router.push({path:'liveShare',query: {id:id,fileId:fileId}})
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend_video{
  display: flex;
  margin: 3% 5px 5px;

.recommend_pic{
  width:40%;
  height: 100px;
  margin-right: 10px;
  ::v-deep .van-image__img{
    border-radius: 8px;
    //width: 150px;
    min-width: 145px;
    max-width: 145px;
  }
}
.recommend_title{
h3{
  display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;
  margin:0;
}
  position: relative;
  flex: 1;
  text-align: left;
  font-size: medium;
}
}
</style>
